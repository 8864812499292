import React from 'react'
import { Helmet } from 'react-helmet'

import '../styles/privacy.css'

import Privacy from '../components/Privacy'

export default () => {
  return (
    <div id="privacy">
      <Helmet>
        <title>REGYM - Privacy and Policy</title>
      </Helmet>
      <Privacy />
    </div>
  )
}
